/** @jsx jsx */
import BackgroundImage from 'gatsby-background-image'
import ulCircle from 'images/ul-circle.png'
import {jsx} from 'theme-ui'
import {graphql} from 'gatsby'
import {AboutWhoFragment} from '../graphqlTypes'
import RawHTML from './RawHTML'
import DonateNowButton from './DonateNowButton'

type Props = {
  data: AboutWhoFragment
  title: string
  content: Array<string>
  buttonText: string
}

const AboutWho: React.FC<Props> = ({data, title, content, buttonText}) => {
  const {bgImage} = data
  return (
    <BackgroundImage
      id="home__hero"
      Tag="div"
      style={{
        backgroundSize: '',
        backgroundPosition: '',
      }}
      sx={{
        py: [30, , 37],
        px: [26, , 40],
        width: '100%',
        height: [1100, 1400, 553],
        display: 'flex',
        '&::before, &::after': {
          backgroundPositionX: 'right',
          backgroundPositionY: ['bottom', , 'top'],
          backgroundSize: ['330%', , 'unset'],
        },
      }}
      backgroundColor="#F4F7F8"
      fluid={bgImage.childImageSharp.fluid}
    >
      <div
        sx={{
          flex: 1,
          maxWidth: ['100%', , 1440 - 208 * 2],
          mx: 'auto',
          display: 'flex',
          alignItems: ['flex-start', , 'center'],
        }}
      >
        <div
          sx={{
            maxWidth: ['100%', 450, 352],
          }}
        >
          <h2
            sx={{
              fontWeight: 400,
              fontSize: 24,
              mt: 0,
              mb: 0,
            }}
          >
            {title}
          </h2>
          <ul
            sx={{
              mt: 28,
              mb: 0,
              pl: [10, 10, 17],
              listStyleImage: `url(${ulCircle})`,
              fontSize: 14,
              lineHeight: '22px',
              color: 'soft',
              b: {
                color: 'black',
              },
              li: {
                mt: [18, 18, 22],
                pl: ['0.5em', '0.5em', '1em'],
              },
            }}
          >
            {content.map((text, i) => (
              <li key={i}>
                <RawHTML>{text}</RawHTML>
              </li>
            ))}
          </ul>
          <DonateNowButton
            sx={{
              mt: 28,
            }}
          >
            {buttonText}
          </DonateNowButton>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default AboutWho

export const query = graphql`
  fragment AboutWho on Query {
    bgImage: file(relativePath: {eq: "about-section-who.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
