/** @jsx jsx */
import {jsx} from 'theme-ui'

type Props = {
  children: string
  className?: string
}

const RawHTML: React.FC<Props> = ({children, className}) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  )
}

export default RawHTML
