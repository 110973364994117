/** @jsx jsx */
import {jsx} from 'theme-ui'

type Props = {
  items: Array<{
    title: string
    description: string
    icon: string
  }>
  title: string
}

const AboutValues: React.FC<Props> = ({items, title}) => {
  return (
    <section
      id="about__values"
      sx={{
        width: ['85%', , 981],
        maxWidth: '90%',
        mt: [80, , 140],
        mx: 'auto',
        mb: 82,
      }}
    >
      <h2
        sx={{
          fontSize: 28,
          my: 0,
          fontWeight: 400,
          color: 'primary',
          textAlign: ['left', , 'center'],
        }}
      >
        {title}
      </h2>
      <div
        sx={{
          mt: [30, , 70],
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        {items.map(({title, description, icon}, index) => {
          return (
            <div
              key={title}
              sx={{
                width: 277,
                height: index > 2 ? 234 : 180,
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <img
                src={icon}
                alt=""
                sx={{
                  width: 28,
                  height: 28,
                  flexShrink: 0,
                }}
              />
              <div sx={{ml: 32}}>
                <h3 sx={{my: 0, fontSize: 18}}>{title}</h3>
                <p
                  sx={{
                    mt: 9,
                    fontSize: 14,
                    lineHeight: '20px',
                    color: 'soft',
                  }}
                >
                  {description}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default AboutValues
