/** @jsx jsx */
import {jsx} from 'theme-ui'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import {AboutTeamFragment} from '../graphqlTypes'
type Props = {
  data: AboutTeamFragment
  title: string
  text: string
  team: teamMember[]
}

export type teamMember = {
  name: string
  position: string
  imageVariable: 'team1' | 'team2' | 'team3' | 'team4' | 'team5' | 'team6'
}

const AboutTeam: React.FC<Props> = ({data, title, text, team}) => {
  return (
    <section
      id="about__team"
      sx={{
        width: ['100%', , 1440 - 122 * 2],
        maxWidth: '100%',
        mx: 'auto',
        pt: 107,
        pl: [27, , 206 - 122],
        pr: [27, , 0],
        pb: [70, , 109],
      }}
    >
      <h2
        sx={{
          my: 0,
          fontSize: 24,
          fontWeight: 400,
        }}
      >
        {title}
      </h2>
      <p
        sx={{
          fontSize: 16,
          lineHeight: '24px',
          maxWidth: 472,
          mt: 16,
          mb: 0,
          color: 'soft',
        }}
      >
        {text}
      </p>
      <div
        sx={{
          mt: [10, , 38],
          display: 'flex',
          width: ['100%', , 1112],
          maxWidth: '100%',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        {team.map(({name, position, imageVariable}) => {
          return (
            <div
              key={name}
              sx={{
                flexBasis: [, '26%', , 'unset'],
                mt: [29, , , 0],
              }}
            >
              <div
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 150,
                  height: 260,
                }}
              >
                <Img fixed={data[imageVariable].childImageSharp.fixed} />
                <span
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: '22px',
                    mt: 12,
                  }}
                >
                  {name}
                </span>
                <span
                  sx={{
                    mt: 4,
                    fontSize: 14,
                    color: 'soft',
                    lineHeight: '22px',
                  }}
                >
                  {position}
                </span>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default AboutTeam

export const query = graphql`
  fragment AboutTeam on Query {
    team1: file(relativePath: {eq: "team-1.png"}) {
      childImageSharp {
        fixed(width: 152, height: 152) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    team2: file(relativePath: {eq: "team-2.png"}) {
      childImageSharp {
        fixed(width: 152, height: 152) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    team3: file(relativePath: {eq: "team-3.png"}) {
      childImageSharp {
        fixed(width: 152, height: 152) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    team4: file(relativePath: {eq: "team-4.png"}) {
      childImageSharp {
        fixed(width: 152, height: 152) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    team5: file(relativePath: {eq: "team-5.png"}) {
      childImageSharp {
        fixed(width: 152, height: 152) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    team6: file(relativePath: {eq: "team-6.png"}) {
      childImageSharp {
        fixed(width: 152, height: 152) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
