/** @jsx jsx */
import Button from 'components/Button'
import BackgroundImage from 'gatsby-background-image'
import {jsx} from 'theme-ui'
import {graphql} from 'gatsby'
import {AboutWithFragment} from '../graphqlTypes'
import RawHTML from './RawHTML'
import DonateNowButton from './DonateNowButton'

type Props = {
  data: AboutWithFragment
  text: string
  buttonText: string
}

const AboutWith: React.FC<Props> = ({data, buttonText, text}) => {
  const {bgImage2} = data
  return (
    <BackgroundImage
      id="home__hero"
      Tag="div"
      style={{
        backgroundSize: '',
        backgroundPosition: '',
      }}
      sx={{
        py: [54, , 37],
        px: [29, , 40],
        width: '100%',
        height: [950, 1050, 595],
        display: 'flex',

        '&::before, &::after': {
          backgroundPositionX: ['0%', '10%', 'center'],
          backgroundPositionY: ['bottom', 'bottom', 'center'],
          backgroundSize: ['190%', '190%', 'unset'],
        },
      }}
      fluid={bgImage2.childImageSharp.fluid}
      backgroundColor="#F4F7F8"
    >
      <div
        sx={{
          flex: 1,
          maxWidth: ['100%', '100%', 1440 - 277 * 2],
          mx: 'auto',
          display: 'flex',
          alignItems: ['flex-start', 'flex-start', 'center'],
          flexDirection: 'row-reverse',
        }}
      >
        <div sx={{maxWidth: ['100%', '100%', 355]}}>
          <RawHTML
            sx={{
              h2: {
                fontWeight: 400,
                fontSize: 24,
                mt: 0,
                mb: 0,
              },
              'p+h2': {
                mt: 24,
              },
              p: {
                fontSize: 14,
                lineHeight: '22px',
              },
            }}
          >
            {text}
          </RawHTML>
          <DonateNowButton
            sx={{
              mt: 28,
            }}
          >
            {buttonText}
          </DonateNowButton>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default AboutWith

export const query = graphql`
  fragment AboutWith on Query {
    bgImage2: file(relativePath: {eq: "about-section-with.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
