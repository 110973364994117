/** @jsx jsx */
import BackgroundImage from 'gatsby-background-image'
import {jsx} from 'theme-ui'
import {graphql} from 'gatsby'
import {AboutHeaderFragment} from '../graphqlTypes'
import aboutPlay from 'images/about-play.svg'
import {useTranslation} from 'react-i18next'

type Props = {
  data: AboutHeaderFragment
  subtitle: string
}

const AboutHeader: React.FC<Props> = ({data, subtitle}) => {
  const {headerImage} = data
  const {t} = useTranslation()
  return (
    <header
      sx={{
        pt: [70, 70, 100],
        // pb: 85,
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h1
        sx={{
          fontSize: 28,
          fontWeight: 400,
          my: 0,
        }}
      >
        UMMATY
      </h1>
      <p
        sx={{
          mt: [14, 14, 18],
          mb: 77,
          fontSize: 16,
          fontWeight: 400,
          color: 'soft',
          width: [283, 283, 'auto'],
        }}
      >
        {subtitle}
      </p>
      {/* <BackgroundImage
        Tag="div"
        sx={{
          width: ['100%', '100%', 1028],
          height: 265,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        fluid={headerImage.childImageSharp.fluid}
      >
        <img src={aboutPlay} alt="" />
      </BackgroundImage> */}
    </header>
  )
}

export default AboutHeader

export const query = graphql`
  fragment AboutHeader on Query {
    headerImage: file(relativePath: {eq: "about-header.png"}) {
      childImageSharp {
        fluid(maxWidth: 1028) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
