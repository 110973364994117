/** @jsx jsx */
import {jsx} from 'theme-ui'
import Layout from 'components/Layout'
import AboutHeader from 'components/AboutHeader'
import {graphql, PageProps} from 'gatsby'
import {AboutQuery} from '../graphqlTypes'
import AboutWho from 'components/AboutWho'
import AboutWith from 'components/AboutWith'
import AboutTeam from 'components/AboutTeam'
import AboutValues from 'components/AboutValues'
import value1 from 'images/about-values-1.svg'
import value2 from 'images/about-values-2.svg'
import value3 from 'images/about-values-3.svg'
import value4 from 'images/about-values-4.svg'
import value5 from 'images/about-values-5.svg'
import value6 from 'images/about-values-6.svg'
import SEO from 'components/SEO'
import {PageContext} from 'gatsbyTypes'
import useAutoSelectLanguage from 'hooks/useAutoSelectLanguage'

const AboutUsPage: React.FC<PageProps<AboutQuery, PageContext>> = ({
  data,
  pageContext,
}) => {
  useAutoSelectLanguage(pageContext)
  return (
    <Layout>
      <SEO
        title="Our team, our mission, our values."
        description="Ummaty is An international muslim humanitarian organization, founded by people who share the same objective: fight poverty and help the most vulnerable amongst us to achieve a better future."
      />
      <AboutHeader
        data={data}
        subtitle="A 100% volunteer based international muslim relief organization."
      />
      <AboutWho
        data={data}
        title="Who we are"
        content={[
          '<b>An international</b> muslim humanitarian organization, founded by people who share the same objective: fight poverty and help the most vulnerable amongst us to achieve a better future.',
          '<b>Inspired by</b> our Islamic values, we conduct projects in different parts of the world, to bring changes to fight poverty, promote education, enable a sustainable development of communities and achieve dignity for all, regardless of race, creed, origin or gender.',
          '<b>Through UMMATY</b>, and with your support, we can have a positive impact and change their lives.',
        ]}
        buttonText="Be the change"
      />
      <AboutTeam
        data={data}
        title="Our leadership team"
        text="Technology makes the world a small village: with a headquarter in Canada, our leadership team members are based out of 3 different continents and works together everyday to deliver our projects:"
        team={[
          {
            name: 'Badr Naciri Bennani',
            position: 'Lead North America - Head Office Manager',
            imageVariable: 'team1',
          },
          {
            name: 'Mouhcine Zerrad',
            position: 'Lead North Africa Program',
            imageVariable: 'team2',
          },
          {
            name: 'Younes Lasri',
            position: 'Lead Africa Subsaharian Program',
            imageVariable: 'team3',
          },
          // {
          //   name: 'Naoufal Rihani',
          //   position: 'Lead Asia Program',
          //   imageVariable: 'team4',
          // },
          {
            name: 'Hassan Boussif',
            position: 'Lead Canada Program',
            imageVariable: 'team5',
          },
          {
            name: 'Adil Naimi',
            position: 'Lead Digital Program',
            imageVariable: 'team6',
          },
        ]}
      />
      <AboutWith
        data={data}
        text={`
      <h2>With Humanity. Everywhere.</h2>
      <p>Our mission is to provide assistance to vulnerable people across the globe, by offering humanitarian services, including programs such as poverty alleviation, potable water supply, sanitation, education and humanitarian assistance to victims of natural or man-made disasters.</p>
      <h2>0% Management fee model</h2>
      <p>Our vision is to become the first muslim international humanitarian organization devoting all donations received to the realization of projects (0% management fee model). Work through these projects to improve the living conditions of communities worldwide and contribute to the maintenance and promotion of human dignity.
      </p>
      `}
        buttonText="Be the change"
      />
      <AboutValues
        title="Our core values"
        items={[
          {
            title: 'Sincerity',
            description:
              'We believe in Sincerity; in the alignment of the inner-self with our actions and the need to fulfil our obligations to humanity.',
            icon: value1,
          },

          {
            title: 'Excellence',
            description:
              'We strive to attain the highest standards and believe in effective teamwork, innovation and professionalism when delivering our services.',
            icon: value3,
          },

          {
            title: 'Non-discrimination',
            description:
              'We believe in the equal value of every human being and the importance of respecting and honoring each individual; regardless of his race, creed, origin or gender.',
            icon: value5,
          },
          {
            title: 'Integrity',
            description:
              'We believe in integrity and accountability when conducting all our temporary and permanent projects. Corruption, conflict of interests and fraud are not tolerated. Wherever we operate, we commit to comply with applicable laws and regulations.',
            icon: value2,
          },
          {
            title: 'Transparency',
            description:
              'We believe that our duty of custodianship and that the trust that people place in us requires transparency with each other, donors, partners and the people we serve. We are responsible stewards of the funds entrusted to us, and strive to eliminate unnecessary expenses.',
            icon: value4,
          },
          {
            title: 'Volunteering',
            description:
              'We promote volunteering as we believe in altruism and selfless actions. By promoting volunteering, especially within the youth, we participate in building communities that care for the most vulnerable, and who find their happiness working for the well-being of others.',
            icon: value6,
          },
        ]}
      />
    </Layout>
  )
}

export default AboutUsPage

export const pageQuery = graphql`
  query About {
    ...AboutHeader
    ...AboutWho
    ...AboutTeam
    ...AboutWith
  }
`
